<template>
  <div class="main-content">
    <div class="main-wrapper postjob">

      <mdc-tab-bar class="steps">
        <mdc-tab :active="section === 'info'" @click="onSelected('info')">
          <span v-if="section === 'info'">1) Job Information</span>
          <span v-else>1)</span>
        </mdc-tab>
        <mdc-tab :active="section === 'design'" @click="onSelected('design')">
          <span v-if="section === 'design'">2) Job Design</span>
          <span v-else>2)</span>
        </mdc-tab>
        <mdc-tab :active="section === 'desc'" @click="onSelected('desc')">
          <span v-if="section === 'desc'">3) Job Description</span>
          <span v-else>3)</span>
        </mdc-tab>
        <mdc-tab :active="section === 'process'" @click="onSelected('process')">
          <span v-if="section === 'process'">3) Hiring Process</span>
          <span v-else>4)</span>
        </mdc-tab>
        <mdc-tab :active="section === 'publish'" @click="onSelected('publish')">
          <span v-if="section === 'publish'">4) Publish</span>
          <span v-else>5)</span>
        </mdc-tab>
      </mdc-tab-bar>

      <section id="info" v-show="section === 'info'">

        <div class="jobrole-section">
          <mdc-textfield type="text" label="Job Title"
          required
          helptext="Please Enter a Job Title"
          helptext-persistent helptext-validation
          v-model="job.rolename" />
        </div>

        <div class="jobrole-section">
          <mdc-body>What type of candidates application are you looking for?</mdc-body>
          <mdc-layout-grid>
              <mdc-layout-cell >
                <mdc-radio v-model="flexType" name="flexType" label="Job Share"  />
                <div class="mdc-form-field jobtypeinfo">
                  <div class="label">This means</div>
                  <div>The role is a full-time position that you will accept Job Share application from Duos</div>
                </div>
              </mdc-layout-cell>

              <mdc-layout-cell >
                <mdc-radio v-model="flexType" name="flexType" label="Job Share Partner"  />
                <div class="mdc-form-field jobtypeinfo">
                  <div class="label">This means</div>
                  <div>The role currently has a candidate associated with it who is looking for a partner</div>
                </div>
              </mdc-layout-cell>

              <mdc-layout-cell >
                <mdc-radio v-model="flexType" name="flexType" label="Part-Time"  />
                <div class="mdc-form-field jobtypeinfo">
                  <div class="label">This means</div>
                  <div>This role is available on a part-time basis</div>
                </div>
              </mdc-layout-cell>
          </mdc-layout-grid>
        </div>

        <mdc-layout-grid v-if="flexType === 'Job Share Partner'">
          <mdc-layout-cell span="6">
            <div class="input-wrapper">
              <p class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent">Candidate Profile</p>
              <mdc-select style="width:100%" v-model="job.candidate" @change="changeCandidate($event)" label="Candidate Profile">
                <select-item  value="Other" />
                <select-item  v-for="cprof in companyProfiles" :key="cprof.id" :value="cprof.id" :text="cprof.displayName"/>
              </mdc-select>
            </div>
          </mdc-layout-cell>

          <mdc-layout-cell span="6">
            <div class="preview-wrapper" style="margin-left:auto;">
              <profile-card v-if="candidate" :profile="candidate"></profile-card>
            </div>
          </mdc-layout-cell>
        </mdc-layout-grid>

        <div v-if="flexType !== 'Part-Time'" class="jobrole-section">
          <div class="input-wrapper">
            <p class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent">Flex schedule</p>
            <day-slider v-model="job.flexschedule"/>
          </div>
        </div>

        <div class="jobrole-section">
          <mdc-select style="width:100%" v-model="job.jobType" label="Job Type">
            <select-item value="Permanent" />
            <select-item value="Contract" />
          </mdc-select>
        </div>

        <div class="jobrole-section">
          <mdc-body>Where is the roles primary location?</mdc-body>
          <mdc-layout-grid>
              <mdc-layout-cell >
                <mdc-radio @change="job.commute='';locationDisabled=false" v-model="locationType" name="locationtype" label="Office"  />
                <div class="mdc-form-field locationinfo">
                  <div class="label">This means</div>
                  <div>The role is primarily located in the office</div>
                </div>
              </mdc-layout-cell>

              <mdc-layout-cell >
                <mdc-radio  @change="job.commute='';locationDisabled=false" v-model="locationType" name="locationtype" label="Mixed"  />
                <div class="mdc-form-field locationinfo">
                  <div class="label">This means</div>
                  <div>The role requires office presence with the option for partial remote working</div>
                </div>
              </mdc-layout-cell>

              <mdc-layout-cell >
                <mdc-radio @change="job.commute='NA';locationDisabled=true" v-model="locationType" name="locationtype" label="Remote"  />
                <div class="mdc-form-field locationinfo">
                  <div class="label">This means</div>
                  <div>The role is primarily remote and will not require</div>
                </div>
              </mdc-layout-cell>
          </mdc-layout-grid>
        </div>

        <div class="jobrole-section">
        <mdc-textfield type="text" label="Office Location"
          required
          :disabled="locationDisabled"
          helptext="Please Enter the Office Location"
          helptext-persistent helptext-validation
          v-model="job.commute" />
        </div>

        <div class="jobrole-section">
          <mdc-body class="label">What is the annual salary range?</mdc-body>
          <mdc-body class="sub-label nomargin">
              This should be the full-time salary, i.e. not prorated
          </mdc-body>
          <mdc-layout-grid>
            <mdc-layout-cell span="3">
              <mdc-textfield v-model="job.salaryFrom" class="currencyInput" label="Annual Salary From" box leading-icon="£"/>
            </mdc-layout-cell>
            <mdc-layout-cell span="3">
              <mdc-textfield v-model="job.salaryTo" class="currencyInput" label="Annual Salary To" box leading-icon="£"/>
            </mdc-layout-cell>
          </mdc-layout-grid>
        </div>

        <mdc-button class="next-button" @click="section = 'design'" raised>Next</mdc-button>
      </section>

      <section id="design" v-show="section === 'design'">

        <p>Provide some details about the nature of the role, this will help inform the candidates and allow DuoMe to tailor the support it will provide.</p>

        <div class="jobrole-section">
          <mdc-body>Does the role have any line management responsibilities?</mdc-body>
          <mdc-radio v-model="management" name="management" label="Yes" />
          <mdc-radio v-model="management" name="management" label="No" />

          <mdc-textfield type="number" label="Approximate size of the team"
          required
          v-if="management === 'Yes'"
          v-model="job.teamSize" />
        </div>

        <div class="jobrole-section">
          <mdc-body class="label">How autonomous is the work undertaken by the role?</mdc-body>
          <mdc-body class="sub-label">
              A highly autonomous role enables the candidate to determine how to achieve the agreed output.
          </mdc-body>
          <div>
            <mdc-button :raised="autonomous === '1'" @click="onSelectedScore('1', 'autonomous')">1</mdc-button>
            <mdc-button :raised="autonomous === '2'" @click="onSelectedScore('2', 'autonomous')">2</mdc-button>
            <mdc-button :raised="autonomous === '3'" @click="onSelectedScore('3', 'autonomous')">3</mdc-button>
            <mdc-button :raised="autonomous === '4'" @click="onSelectedScore('4', 'autonomous')">4</mdc-button>
            <mdc-button :raised="autonomous === '5'" @click="onSelectedScore('5', 'autonomous')">5</mdc-button>
          </div>
          <p class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent"> 1 - Not Autonomous 5 - Fully autonomous</p>
        </div>

        <div class="jobrole-section">
          <mdc-body class="label">How predictable is the volume of work for this role?</mdc-body>
          <mdc-body class="sub-label">
              E.g In a typical scenario the volume of work is known and understood.
          </mdc-body>
          <div>
            <mdc-button :raised="predictable === '1'" @click="onSelectedScore('1', 'predictable')">1</mdc-button>
            <mdc-button :raised="predictable === '2'" @click="onSelectedScore('2', 'predictable')">2</mdc-button>
            <mdc-button :raised="predictable === '3'" @click="onSelectedScore('3', 'predictable')">3</mdc-button>
            <mdc-button :raised="predictable === '4'" @click="onSelectedScore('4', 'predictable')">4</mdc-button>
            <mdc-button :raised="predictable === '5'" @click="onSelectedScore('5', 'predictable')">5</mdc-button>
          </div>
          <p class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent"> 1 - Not predictable 5 - fully predictable</p>
        </div>

        <div class="jobrole-section">
          <mdc-body class="label">How specialised is the knowledge required for this role?</mdc-body>
          <mdc-body class="sub-label">
              If the role is very specialised its common for very few people to know how to perform due to the knowledge required
          </mdc-body>
          <div>
            <mdc-button :raised="specialised === '1'" @click="onSelectedScore('1', 'specialised')">1</mdc-button>
            <mdc-button :raised="specialised === '2'" @click="onSelectedScore('2', 'specialised')">2</mdc-button>
            <mdc-button :raised="specialised === '3'" @click="onSelectedScore('3', 'specialised')">3</mdc-button>
            <mdc-button :raised="specialised === '4'" @click="onSelectedScore('4', 'specialised')">4</mdc-button>
            <mdc-button :raised="specialised === '5'" @click="onSelectedScore('5', 'specialised')">5</mdc-button>
          </div>
          <p class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent"> 1 - Highly generalised - 5 very specialised</p>
        </div>

        <div class="jobrole-section">
          <mdc-body class="label">How would you deem the required outcomes of the role?</mdc-body>
          <mdc-body class="sub-label">
              E.g. are the expected deliverables
          </mdc-body>
          <mdc-layout-grid>
              <mdc-layout-cell >
                <mdc-radio v-model="outcome" name="outcomes" label="Fixed"  />
                <div class="mdc-form-field outcomeinfo">
                  <div class="label">This means</div>
                  <div>The outcomes are clearly defined and tend to consistent</div>
                </div>
              </mdc-layout-cell>

              <mdc-layout-cell >
                <mdc-radio v-model="outcome" name="outcomes" label="Varied"  />
                <div class="mdc-form-field outcomeinfo">
                  <div class="label">This means</div>
                  <div>The outcomes vary depending upon need but can still be planned for</div>
                </div>
              </mdc-layout-cell>

              <mdc-layout-cell >
                <mdc-radio v-model="outcome" name="outcomes" label="Evolving"  />
                <div class="mdc-form-field outcomeinfo">
                  <div class="label">This means</div>
                  <div>The outcomes require are constantly changing based upon business need and make planning difficult</div>
                </div>
              </mdc-layout-cell>
          </mdc-layout-grid>
        </div>

        <mdc-button class="next-button" @click="section = 'desc'" raised>Next</mdc-button>
      </section>

      <section id="desc" v-show="section === 'desc'">
        <div class="input-wrapper">
          <p class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent">Job Description</p>
          <tiptap-editor @onTextUpdate="onTextUpdate" :title="'Description'" :cotent="job.description" :contentKey="'description'"></tiptap-editor>
        </div>

        <div class="input-wrapper">
          <p class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent">Key Skills</p>
          <mdc-chip-set >
            <mdc-chip v-for="(skill, index) in skills" :key="index" trailing-icon="cancel">{{skill.name}}</mdc-chip>
          </mdc-chip-set>
        </div>

        <div class="jobrole-section">
        <mdc-layout-grid>
          <mdc-layout-cell >
            <mdc-textfield type="text"
            dense
            fullwidth
            label="New Skill"
            class=""
            v-model="newSkill" />
          </mdc-layout-cell>
          <mdc-layout-cell>
            <mdc-button class="addButton" @click="addSkill">
              <mdc-icon icon="add"></mdc-icon>
            </mdc-button>
          </mdc-layout-cell>
        </mdc-layout-grid>
      </div>

        <div class="input-wrapper">
          <p class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent">Flexible Working Policy</p>
        </div>

        <mdc-button class="next-button" @click="section = 'process'" raised>Next</mdc-button>

      </section>

      <section id="process" v-show="section === 'process'">

        <div class="input-wrapper">
          <p class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent">Screening Questions</p>

          <mdc-list interactive two-line>
            <div v-for="(quest, index) in screeningQuestions" :key="index">
              <mdc-list-item @click="setExpanded(index)">
                <span>{{quest.question}}</span>
                <span slot="secondary">{{quest.type}}</span>
                <i v-if="expanded !== index" slot="end-detail"  class="material-icons">keyboard_arrow_down</i>
                <i v-else slot="end-detail" class="material-icons">keyboard_arrow_up</i>
              </mdc-list-item>
              <div v-if="expanded == index">
                <div style="padding:16px;">

                  <mdc-textfield
                    type="text"
                    style="width:45%"
                    label="Question"
                    v-model="quest.question" />

                  <mdc-select style="height:56px;width:45%;" v-model="quest.type" label="Answer Type">
                    <select-item value="Free Text" />
                    <select-item value="Number" />
                  </mdc-select>

                </div>
              </div>
            </div>
            <div>
              <mdc-button class="addButton" @click="addScreenQ">
                <mdc-icon icon="add"></mdc-icon>
              </mdc-button>
            </div>
          </mdc-list>

        </div>

        <div class="input-wrapper">
          <p class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent">Application Type</p>
          <mdc-switch v-model="job.cvMandatory" :label="'CV Mandatory'"></mdc-switch>
        </div>

        <mdc-layout-grid>
          <mdc-layout-cell span="6">
            <mdc-textfield type="text" label="Hiring Manager Name"
            v-model="job.nameHiringManager" />
          </mdc-layout-cell>
          <mdc-layout-cell span="6">
            <mdc-textfield type="text" label="Hiring Manager Email"
            v-model="job.emailHiringManager" />
          </mdc-layout-cell>
        </mdc-layout-grid>

        <mdc-layout-grid>
          <mdc-layout-cell span="6">
            <mdc-textfield type="text" label="Recruiter Name"
            v-model="job.nameRecruiter" />
          </mdc-layout-cell>
          <mdc-layout-cell span="6">
            <mdc-textfield type="text" label="Recruiter Email"
            v-model="job.emailRecruiter" />
          </mdc-layout-cell>
        </mdc-layout-grid>

        <mdc-layout-grid >
          <mdc-layout-cell span="6">
            <mdc-textfield type="text" label="Co-ordinator Name"
            v-model="job.nameCoordinator" />
          </mdc-layout-cell>
          <mdc-layout-cell span="6">
            <mdc-textfield type="text" label="Co-ordinator Email"
            v-model="job.emailCoordinator" />
          </mdc-layout-cell>
        </mdc-layout-grid>

        <mdc-layout-grid style="margin-top:16px;" >
          <mdc-layout-cell span="6">
            <mdc-select style="width:100%" v-model="job.approvalFlow" label="Approval Flow">
              <select-item value="Self" />
              <select-item value="Hiring Manager" />
              <select-item value="Recruiter" />
            </mdc-select>
          </mdc-layout-cell>
        </mdc-layout-grid>

        <mdc-button class="next-button" @click="section = 'publish'"  raised>Next</mdc-button>

      </section>

      <section id="publish" v-show="section === 'publish'">

        <div class="input-wrapper">
          <p class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent">Prepare to Publish</p>
          <mdc-button class="next-button" raised><i class="material-icons mdc-button__icon">share</i> Share for Review</mdc-button>
        </div>

        <div class="input-wrapper">
          <p class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent">Job Description</p>
          <div v-html="job.description" class="mdc-elevation mdc-elevation--z2 desc-wrapper"></div>
        </div>

        <div class="input-wrapper">
          <p class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent">Preview</p>
          <div class="preview-wrapper">
            <job-role-card :jobrole="job"></job-role-card>
          </div>
        </div>

        <mdc-button @click="postJob" class="next-button" raised>Publish</mdc-button>

      </section>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import JobRoleCard from '../components/JobRoleCard.vue'
import ProfileCard from '../components/ProfileCard.vue'
import DaySlider from '../components/DaySlider'
export default {
  name: 'postjob',
  components: {
    JobRoleCard,
    ProfileCard,
    TiptapEditor: () => import('../components/Tiptap'),
    DaySlider
  },
  data () {
    return {
      section: 'info',
      candidateInRole: false,
      candidate: null,
      expanded: -1,
      screeningQuestions: [],
      flexType: null,
      locationType: null,
      management: 'No',
      autonomous: null,
      predictable: null,
      specialised: null,
      outcome: null,
      newSkill: '',
      skills: [],
      locationDisabled: false,
      job: {},
      defaultJob: {
        title: '',
        location: '',
        rolename: '',
        companyId: null,
        company: '',
        candidateInRole: '',
        candidate: null,
        photoURL: '',
        flexschedule: 0,
        salaryFrom: '',
        salaryTo: '',
        workstyle: '',
        commute: '',
        skills: [],
        nameHiringManager: '',
        emailHiringManager: '',
        nameRecruiter: '',
        emailRecruiter: '',
        nameCoordinator: '',
        emailCoordinator: ''
      }
    }
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'profiles'
    ]),
    ...mapState({
      company: state => state.company.company
    }),
    companyLogo () {
      if (this.profile && this.company) {
        return this.company.logo
      }
      return '/static/img/Logo-white.svg'
    },
    companyProfiles () {
      if (!this.profile) return null
      const companyId = this.profile.companyId
      if (!companyId) return null
      if (!this.profiles) return null
      return this.profiles.filter((profile) => profile.companyId === companyId)
    }
  },
  methods: {
    ...mapActions([
      'addJob',
      'setSnackMessage',
      'setAction',
      'setPageLoad'
    ]),
    onSelectedScore: function (val, field) {
      this[field] = val
    },
    addSkill: function () {
      if (this.newSkill !== '') {
        const skill = this.newSkill
        this.skills.push({ name: skill })
        this.newSkill = ''
      }
    },
    addScreenQ: function () {
      this.screeningQuestions.push({
        question: 'Not specified',
        type: 'Free Text'
      })
      this.setExpanded(this.screeningQuestions.length - 1)
    },
    setExpanded: function (indx) {
      if (this.expanded === indx) {
        this.expanded = -1
      } else {
        this.expanded = indx
      }
    },
    postJob: function () {
      this.job.screeningQuestions = this.screeningQuestions
      this.job.workstyle = this.locationType
      this.job.flexType = this.flexType
      this.job.management = this.management
      this.job.autonomous = this.autonomous
      this.job.predictable = this.predictable
      this.job.specialised = this.specialised
      this.job.skills = this.skills
      this.job.outcome = this.outcome
      this.addJob({ job: this.job }).then(() => {
        this.setSnackMessage('Job Role Published')
        this.setAction('jobs')
        this.$router.push('/dash')
      })
    },
    getProfile (profileId) {
      if (profileId === this.profile.id) {
        return this.profile
      }
      const profileMap = new Map(this.profiles.map(i => [i.id, i]))
      return profileMap.get(profileId)
    },
    changeSlider: function (val) {
      this.flexScheduleDisplay = val + ' days'
    },
    changeCandidateInRole: function (event) {
      this.candidateInRole = event === 'Yes'
    },
    changeCandidate: function (event) {
      if ((event !== '') && (event !== 'Other')) {
        this.candidate = this.getProfile(event)
      } else {
        this.candidate = null
      }
    },
    onTextUpdate: function (text, contentKey) {
      this.job[contentKey] = text
    },
    onSelected (section) {
      this.section = section
    },
    setCompany (companyId, name, logo) {
      this.job.companyId = companyId
      this.job.company = name
      this.job.photoURL = logo
    }
  },
  mounted () {
    this.job = { ...this.defaultJob }
    if (this.company) {
      this.setCompany(this.company.id, this.company.name, this.company.logo)
    }
    setTimeout(() => {
      this.setPageLoad(false)
    }, 600)
  },
  watch: {
    company: function (newC, oldC) {
      if (newC) {
        this.setCompany(newC.id, newC.name, newC.logo)
      } else {
        this.setCompany('5b8uqkZSX1GQoE0YTb6i', 'BBC', '/static/img/bbc.png')
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.postjob .sub-label {
  font-size:0.8em;
  margin-top:0;
  margin-bottom:24px;
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
}
.postjob .jobrole-section .label {
  margin-bottom:4px;
}
.postjob .sub-label.nomargin {
  margin-bottom:0;
}
.postjob .jobrole-section {
  margin-bottom:44px;
}
.postjob .locationinfo, .postjob .jobtypeinfo, .postjob .outcomeinfo{
  display:block;
  margin-top:6px;
  margin-left:26px;
  border-left:2px solid silver;
  padding-left:6px;
}
.postjob .locationinfo .label, .postjob .jobtypeinfo .label, .postjob .outcomeinfo .label{
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
  margin-bottom:12px;
}
.postjob .mdc-radio-wrapper {
  border:1px solid silver;
  border-radius:20px;
  padding-right:20px;
}
.postjob .currencyInput input{
  margin-left:24px;
  margin-right:24px;
  height:48px;
  text-align: right;
}
.postjob .mdc-list--two-line .mdc-list-item {
    height: 52px;
    border-bottom: 1px solid silver;
    padding-top: 12px;
    padding-bottom: 12px;
}
.postjob .mdc-layout-grid {
  padding:0;
}
.postjob .preview-wrapper {
  width:350px;
}
.postjob .desc-wrapper {
  padding:16px;
}
.postjob .mdc-floating-label {
  margin-left:12px;
}
.mdc-select--box .mdc-floating-label {
  left: unset;
}
.postjob .mdc-textfield-wrapper {
  width:100%;
}
.postjob .mdc-textfield {
  width:100%;
  border-bottom-color: rgba(0, 0, 0, 0.42);
  background-color: whitesmoke;
  height:56px;
}

.postjob .mdc-textfield input.mdc-text-field__input {
  padding-left:12px !important;
}

.postjob .input-wrapper {
  padding-top:12px;
  padding-bottom:22px;
}
.postjob .input-wrapper .mdc-text-field-helper-text {
  margin-bottom:8px;
  color: rgba(0, 0, 0, 0.6);
}
.postjob .steps {
  margin: 0;
}
.postjob .steps .mdc-tab-bar__indicator {
  display:none;
}
.postjob .steps .mdc-tab {
  color:silver;
  text-align: left;
  min-width: unset;
}
.postjob .steps .mdc-tab.disabled {
  cursor:default;
}
.postjob .steps .mdc-tab--active  {
  border: 2px solid var(--mdc-theme-primary, #2D9CDB);
  color: var(--mdc-theme-primary, #2D9CDB);
  background-color:#abe0f0;
}
.postjob .next-button {
  margin-top:16px;
  margin-bottom:50px;
}
.postjob .mdc-switch-label {
  padding-left:24px;
}
</style>
