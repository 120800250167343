<template>
  <div v-bind:class="{ready: (ready && modal), notready: (!ready && modal)}" class="slider-wrapper day-slider">
    <input class="focus-ancor" style="opacity:0;z-index:-1;" :tabindex="tabindex" @blur="blurSlider" @focus="focusSlider" >
    <vue-slider :useKeyboard="keyboardfoucs" ref="slider" v-model="valueNum" :data="data" :marks="marks" :tooltip="'always'" :tooltip-formatter="tooltip">
      <template v-slot:step="{ label, active }">
        <div :aria-label="label" :class="['custom-step', { active }]"></div>
      </template>
    </vue-slider>
  </div>
</template>

<script>
import 'vue-slider-component/theme/material.css'

export default {
  name: 'DaySlider',
  model: {
    prop: 'value',
    event: 'change'
  },
  components: {
    VueSlider: () => import('vue-slider-component')
  },
  props: {
    value: {
      required: false
    },
    modal: {
      type: Boolean,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    show: {
      type: Boolean,
      default: false
    },
    tabindex: {
    }
  },
  methods: {
    focusSlider () {
      this.keyboardfoucs = true
      this.$refs.slider.focus()
    },
    blurSlider () {
      this.keyboardfoucs = false
      this.$refs.slider.blur()
    },
    onChange (val) {
      this.formatDays(val)
      this.$emit('change', this.valueDisplay)
    },
    formatDays (value) {
      if (value === '') {
        value = '0 Days'
        this.valueDisplay = `${value} Days`
      }
      if (value !== null) {
        value = ('' + value).split(' ')[0]
        if (this.valueNum !== value) {
          let valFloat = parseFloat(value)
          if (isNaN(valFloat)) {
            value = '0 Days'
            valFloat = 0
          }
          this.valueNum = '' + valFloat
        }
        this.valueDisplay = `${value} Days`
      }
    },
    tooltip (val) {
      return `${val} Days`
    }
  },
  data () {
    return {
      keyboardfoucs: false,
      valueNum: 0,
      valueDisplay: '0 Days',
      ready: false,
      active: true,
      data: ['0', '0.5', '1', '1.5', '2', '2.5', '3', '3.5', '4', '4.5', '5'],
      marks: (val) => {
        if (val % 1 === 0) {
          return `${val}`
        } else {
          return '.'
        }
      }
    }
  },
  mounted () {
    const self = this
    this.formatDays(this.value)
    setTimeout(function () {
      self.ready = true
    }, 100)
  },
  watch: {
    value (newv, oldv) {
      this.formatDays(this.value)
    },
    valueNum (newv, oldv) {
      this.onChange('' + this.valueNum)
    },
    show (newr, oldr) {
      if (newr) {
        this.formatDays(this.value)
      }
    }
  }
}
</script>

<style>
.vue-slider-dot {
  position: absolute;
  will-change: transform;
  -webkit-transition: all 0s;
  transition: all 0s;
  z-index: 9 !important;
}

.vue-slider-dot-handle::after {
  background-color: rgba(52, 152, 219, 0.38);
}

.vue-slider-dot-handle {
  background-color: white;
  box-shadow: 0 1px 2px rgba(0,0,0,.25);
  border: 0.25px solid #BCBDBC;
}

.vue-slider-dot-handle.active {
box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.vue-slider-dot-handle:hover {
box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
}

.vue-slider-rail {
  background-color: #BCBDBC;
}

.vue-slider-process {
  background-color: #2D9BDB;
  z-index: 8;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.vue-slider-dot-tooltip-inner-top {
  border-radius: 3px;
  transform: translate(0, 20%) !important;
  background-color: #2D9BDB;
  color: white;
}

.vue-slider-marks {
  z-index: 8;
  overflow: visible;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.vue-slider-marks .custom-step{
  border-color: #BCBDBC;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  background-color:white;
  width:6px;
  height:6px;
  margin-top:-3px;
  margin-left:-3px;
}

.vue-slider-marks .custom-step.active {
  border-color: #2D9BDB;
}

.vue-slider-marks .vue-slider-mark-label {
  z-index:9 !important;
  left:-70% !important;
  transform: none !important;
}

.vue-slider-mark:nth-child(even) .custom-step {
  border-radius:0;
  width:0px;
  height:2px;
}

.vue-slider-mark:nth-child(even) .vue-slider-mark-label {
  display:none
}

.vue-slider-ltr .vue-slider-mark {
  z-index:9;
  top:0 !important;
  overflow:visible !important;
  transform: none !important;
  margin-left: -2px;
}

.vue-slider-dot-tooltip-inner-top .vue-slider-dot-tooltip-text {
  width:80px;
  font-size:0.9em;
  transform: none !important;
}

.day-slider.slider-wrapper {
  position: relative;
  width:calc(100% - 64px);
  height:50px;
  padding-top: 6px;
  padding-bottom: 26px;
  margin-right:32px;
  margin-left:32px;
}
</style>
