<template>
  <div class="jobrole-card">
    <div class="jobrole-card-wrapper">
      <mdc-card class="jobrole-wrapper">
        <div class="jobrole"
        v-bind:class="{ active: isActive }"
        @click="isActive = !isActive"
        >
          <div class="jobrole-image">
            <div class="company-logo-wrapper">
              <img v-if="jobrole.photoURL" :src="jobrole.photoURL" class="company-logo"/>
            </div>
          </div>
          <div class="jobrole-text">
            <div>
              <mdc-body class="jobrole-headline">{{jobrole.rolename}}</mdc-body>
              <mdc-subheading>{{jobrole.company}}</mdc-subheading>
            </div>
          </div>
        </div>
      </mdc-card>
      <mdc-card class="jobrole-details">
        <div class="link-row link-row-top">
          <div>
            <span class="mdc-icon duome-icon">
              <img src="/static/img/icons/location_outline_light.svg">
            </span>
            <div class="jobrole-stat">
              <mdc-body tag="span" class="value">{{jobrole.commute}}</mdc-body>
              <span><img src="/static/img/separator.svg"></span>
              <mdc-body tag="span" class="field" typo="body2">Location</mdc-body>
            </div>
          </div>
          <div>
            <span class="mdc-icon duome-icon">
              <img src="/static/img/icons/query_builder_light.svg">
            </span>
            <div class="jobrole-stat">
              <mdc-body tag="span" class="value">{{jobrole.flexschedule}} days</mdc-body>
              <span :class="'days-' + ('' + jobrole.flexschedule).replace('.','-')">
                <span class="halfday"></span>
                <span class="day"></span>
                <span class="halfday"></span>
                <span class="day"></span>
                <span class="halfday"></span>
                <span class="day"></span>
                 <span class="halfday"></span>
                <span class="day"></span>
                <span class="halfday"></span>
                <span class="day"></span>
              </span>
              <mdc-body tag="span" class="field" typo="body2">Flex Schedule</mdc-body>
            </div>
          </div>
          <div>
            <span class="mdc-icon duome-icon">
              <img src="/static/img/icons/location_city_light.svg">
            </span>
            <div class="jobrole-stat">
              <mdc-body tag="span" class="value">{{jobrole.workstyle}}</mdc-body>
              <span><img src="/static/img/separator.svg"></span>
              <mdc-body tag="span" class="field" typo="body2">Work style</mdc-body>
            </div>
          </div>
        </div>
        <div class="link-row link-row-bottom" style="margin-top:12px;">
          <div>
            MORE
          </div>
          <div>
            <span @click.prevent.stop="shareopen = true">
              <span class="mdc-icon duome-icon">
                <img src="/static/img/icons/share_outline_light.svg">
              </span>
            </span>
          </div>

          <div v-bind:class="{ active: isActive }"
            @click.prevent.stop="isActive = !isActive"
          >
          <mdc-icon class="bookmark">
            <img class="icon" src="/static/img/icons/bookmark_border_light.svg">
            <img class="icon-active" src="/static/img/icons/bookmark_border_light_filled.svg">
          </mdc-icon>
          </div>
        </div>
      </mdc-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JobRoleCard',
  props: {
    jobrole: {
      type: Object,
      default: function () {
        return {}
      }
    },
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.jobrole-wrapper {
  overflow: hidden;
  background-color: white;
  position: relative;
  border-radius: 13px;
  width: 100%;
  overflow: hidden;
  padding-top: calc(154 / 238 * 100%);
  position: relative;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}
.jobrole {
  display:flex;
  flex-flow: row;
  position: absolute;
  top: 0;
  left: 0;
  width: 101%;
  height: 100%;
  align-items: center;
}
.jobrole .icon {
  color: #828282;
}
.jobrole-image {
  width: 50%;
}
.jobrole-text {
  width: 50%;
  height: 50%;
  text-align: center;
  padding: 5%;
  padding-top: 30% !important;
  overflow: hidden;
}
.link-row {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
.link-row > div {
  flex: 1;
  flex-direction: row;
  display: flex;
  text-align: center;
  align-items: center;
}
.link-row-top > div {
  flex: 0;
}
.link-row.link-row-top {
  min-height: 60px;
}
.link-row .mdc-icon.duome-icon {
    margin-right: 3px;
}
.link-row-bottom > div:nth-of-type(2),
.link-row-bottom > div:nth-of-type(3) {
    flex: 0 0 20%;
    min-width: 30px;
    justify-content: flex-end;
}
.jobrole-stat {
    display: flex;
    flex-direction: column;
    line-height: 0;
    flex: 1;
}
.jobrole-card .mdc-subheading {
  margin:0;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.jobrole-card .mdc-body {
  margin:0;
  font-size: 12px;
  line-height: 1;
}
span[class^="days-"] {
  margin-top: 3px;
}
span[class^="days-"] .day {
  height: 2px;
  background: #E0E0E0;
  display: inline-block;
  width: 13%;
  margin: 0 0.1%;
}

span.days-1 .day:nth-of-type(-n+1) {
  background: #AD1457;
}
span.days-2 .day:nth-of-type(-n+2) {
  background: #AD1457;
}
span.days-3 .day:nth-of-type(-n+3) {
  background: #AD1457;
}
span.days-4 .day:nth-of-type(-n+4) {
  background: #AD1457;
}
span.days-5 .day:nth-of-type(-n+5) {
  background: #AD1457;
}
.jobrole-status-bar {
  display:flex;
  width:100%
}
.jobrole-status-bar {
  display:flex;
  width:100%
}
.jobrole-details {
  padding: 5% 2% 0 2%;
  font-family: 'Roboto', sans-serif;
  background: none;
  box-shadow: none;
}
.jobrole-details .value {
    display: block;
    line-height: 1;
    color: #333333;
    min-height: 9px;
}
.jobrole-details .field {
  font-style: italic;
  margin-top: 3px;
}
.jobrole-details .duome-icon img {
  width:20px;
  height: 20px;
}

.company-logo-wrapper,
.company-logo-wrapper img {
    max-width: 100%;
}
.company-logo-wrapper {
    padding: 0 10%;
}
.bookmark {
  display:none;
  cursor: pointer;
}
.bookmark .icon {
  display: block;
}
.bookmark .icon-active {
  display:none;
}
.active .bookmark .icon-active {
  display: block;
}
.active .bookmark .icon {
  display: none;
}
span[class^="days-"] {
  margin-top: 3px;
  position:relative;
}
span[class^="days-"] .day {
  height: 2px;
  background: #E0E0E0;
  display: inline-block;
  width: 13%;
  margin: 0 0.1%;
}
span[class^="days-"] .halfday {
  position:absolute;
  height: 2px;
  background: #E0E0E0;
  display: inline-block;
  width: 7%;
  margin: 0 0.1%;
  z-index:-1;
  bottom:0px;
}
span.days-0-5 .halfday:nth-of-type(-n+1) {
  background: #AD1457;
  z-index: 1;
}
span.days-1 .day:nth-of-type(-n+2) {
  background: #AD1457;
  z-index: 1;
}
span.days-1-5 .day:nth-of-type(-n+2) {
  background: #AD1457;
  z-index: 1;
}
span.days-1-5 .halfday:nth-of-type(-n+3) {
  background: #AD1457;
  z-index: 1;
}
span.days-2 .day:nth-of-type(-n+4) {
  background: #AD1457;
  z-index: 1;
}
span.days-2-5 .day:nth-of-type(-n+4) {
  background: #AD1457;
  z-index: 1;
}
span.days-2-5 .halfday:nth-of-type(-n+5) {
  background: #AD1457;
  z-index: 1;
}
span.days-3 .day:nth-of-type(-n+6) {
  background: #AD1457;
  z-index: 1;
}
span.days-3-5 .day:nth-of-type(-n+6) {
  background: #AD1457;
  z-index: 1;
}
span.days-3-5 .halfday:nth-of-type(-n+7) {
  background: #AD1457;
  z-index: 1;
}
span.days-4 .day:nth-of-type(-n+8) {
  background: #AD1457;
  z-index: 1;
}
span.days-4-5 .day:nth-of-type(-n+8) {
  background: #AD1457;
  z-index: 1;
}
span.days-4-5 .halfday:nth-of-type(-n+9) {
  background: #AD1457;
  z-index: 1;
}
span.days-5 .day:nth-of-type(-n+10) {
  background: #AD1457;
  z-index: 1;
}
</style>
